<template>
  <div class="big-form">
    <div class="login-form">
      <p
        :style="{
          color: $store.getters.color.fontColor,
          backgroundColor: $store.getters.color.color1,
        }"
      >
        {{ $t("password.newPassword") }}
      </p>
      <div class="form-container">
        <label for="field1">{{ $t("password.currentPassword") }}</label>
        <input
          type="password"
          id="field1"
          v-model="oldPassword"
          @keypress.enter="
            if (passwordConfirmation && password && oldPassword) {
              submitPassword();
            }
          "
          required
        />
        <label for="field2">{{ $t("password.newPassword") }}</label>
        <input
          type="password"
          id="field2"
          v-model="password"
          @keypress.enter="
            if (passwordConfirmation && password && oldPassword) {
              submitPassword();
            }
          "
          required
        />
        <label for="field3">{{ $t("password.confirmation") }}</label>
        <input
          type="password"
          id="field3"
          v-model="passwordConfirmation"
          @keypress.enter="
            if (passwordConfirmation && password && oldPassword) {
              submitPassword();
            }
          "
          required
        />
        <p class="error-message" v-if="passwordCheck != ''">
          {{ passwordCheck }}
        </p>
        <br v-if="passwordCheck != ''" />
        <button
          type="button"
          @click="submitPassword()"
          class="login-button"
          :disabled="checkForm"
        >
          {{ $t("buttons.go") }}
        </button>
      </div>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "ChangePassword",
  data() {
    return {
      oldPassword: "",
      password: "",
      passwordConfirmation: "",
      errorMessage: "",
    };
  },
  methods: {
    submitPassword: async function () {
      try {
        if (this.$store.getters.userType == "client") {
          await HandleApi.clientPwd(this.$store.getters.userId, {
            password: this.password,
            oldPassword: this.oldPassword,
          });
          this.$store.dispatch(
            "push_notification_message",
            this.$t("notification.updated", {
              object: this.$t("password.password"),
            })
          );
          this.$router.push("/client/" + this.$store.getters.userId);
        } else {
          if (this.$store.getters.userType == "tourAgencyEmployee") {
            await HandleApi.employeePwd(this.$store.getters.userId, {
              password: this.password,
              oldPassword: this.oldPassword,
            });
            this.$store.dispatch(
              "push_notification_message",
              this.$t("notification.updated", {
                object: this.$t("password.password"),
              })
            );
            this.$router.push("/employee/" + this.$store.getters.userId);
          } else {
            if (this.$store.getters.userType == "travelAssistEmployee") {
              await HandleApi.travelassistPwd(this.$store.getters.userId, {
                password: this.password,
                oldPassword: this.oldPassword,
              });
              this.$store.dispatch(
                "push_notification_message",
                this.$t("notification.updated", {
                  object: this.$t("password.password"),
                })
              );
              this.$router.push("/travelassist/" + this.$store.getters.userId);
            }
          }
        }
      } catch (err) {
        this.$store.dispatch("push_error_message", err.response.data);
      }
    },
  },
  computed: {
    checkForm: function () {
      if (!this.passwordConfirmation || !this.password || !this.oldPassword) {
        return true;
      }
      if (this.password != this.passwordConfirmation) {
        return true;
      }
      if (this.password.length < 8) {
        return true;
      }
      return false;
    },
    passwordCheck: function () {
      if (this.errorMessage) {
        return this.errorMessage;
      }
      if (this.password.length < 8 && this.password.length > 0) {
        return this.$t("password.min8caracters");
      }
      if (
        this.password != this.passwordConfirmation &&
        this.password.length > 0 &&
        this.passwordConfirmation.length > 0
      ) {
        return this.$t("password.confirmationMustMatch");
      }
      return "";
    },
  },
};
</script>

<style scoped>
.big-form {
  text-align: center;
}

.login-form {
  margin: auto auto;
  display: inline-block;
  border-radius: 20px;
  box-shadow: 5px 5px 5px 5px lightgrey;
}

.login-form div {
  padding: 10px;
}

label {
  margin-right: 10px;
}

p {
  margin: 0;
  padding: 20px;
  font-size: 25px;
  color: white;
  font-weight: bold;
  background-color: #00909f;
  border-radius: 20px 20px 0px 0px;
  border: 2px solid lightgrey;
  text-align: left;
}

.form-container {
  border: 2px solid lightgrey;
  border-top: none;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  border-radius: 0 0 20px 20px;
}

.form-container label {
  justify-self: center;
  align-self: center;
}

.form-container input {
  margin-right: 10px;
}

.error-message {
  border: 2px solid red;
  color: red;
  background-color: #ecb2b2;
  margin: 0 auto;
  border-radius: 10px;
  grid-column-start: 1;
  grid-column-end: span 2;
  font-size: 18px;
}

.login-button {
  grid-column-start: 1;
  grid-column-end: span 2;
  color: white;
  border-radius: 10px;
  border: none;
  background-color: #008467;
  font-weight: bold;
  font-size: 20px;
  padding: 10px;
  width: 100px;
  align-self: center;
  justify-self: center;
}

.login-button:disabled {
  background-color: rgb(139, 196, 169);
}
</style>
