var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"big-form"},[_c('div',{staticClass:"login-form"},[_c('p',{style:({
        color: _vm.$store.getters.color.fontColor,
        backgroundColor: _vm.$store.getters.color.color1,
      })},[_vm._v(" "+_vm._s(_vm.$t("password.newPassword"))+" ")]),_c('div',{staticClass:"form-container"},[_c('label',{attrs:{"for":"field1"}},[_vm._v(_vm._s(_vm.$t("password.currentPassword")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.oldPassword),expression:"oldPassword"}],attrs:{"type":"password","id":"field1","required":""},domProps:{"value":(_vm.oldPassword)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if (_vm.passwordConfirmation && _vm.password && _vm.oldPassword) {
            _vm.submitPassword();
          }},"input":function($event){if($event.target.composing){ return; }_vm.oldPassword=$event.target.value}}}),_c('label',{attrs:{"for":"field2"}},[_vm._v(_vm._s(_vm.$t("password.newPassword")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password","id":"field2","required":""},domProps:{"value":(_vm.password)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if (_vm.passwordConfirmation && _vm.password && _vm.oldPassword) {
            _vm.submitPassword();
          }},"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('label',{attrs:{"for":"field3"}},[_vm._v(_vm._s(_vm.$t("password.confirmation")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirmation),expression:"passwordConfirmation"}],attrs:{"type":"password","id":"field3","required":""},domProps:{"value":(_vm.passwordConfirmation)},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if (_vm.passwordConfirmation && _vm.password && _vm.oldPassword) {
            _vm.submitPassword();
          }},"input":function($event){if($event.target.composing){ return; }_vm.passwordConfirmation=$event.target.value}}}),(_vm.passwordCheck != '')?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.passwordCheck)+" ")]):_vm._e(),(_vm.passwordCheck != '')?_c('br'):_vm._e(),_c('button',{staticClass:"login-button",attrs:{"type":"button","disabled":_vm.checkForm},on:{"click":function($event){return _vm.submitPassword()}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.go"))+" ")])])]),_c('br'),_c('br')])}
var staticRenderFns = []

export { render, staticRenderFns }